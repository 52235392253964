import { DOMAttributes, useMemo } from "react";
import camelcaseKeys from "camelcase-keys";
import useCustomElement from "../../../../hooks/useCustomElement";
import { ICustomHeader } from "../../../../types/railsTypes";

type CustomEvents<K extends string> = { [key in K]: (event: CustomEvent) => void };
type CustomElement<T, K extends string> = Partial<
  T & DOMAttributes<T> & { children: any } & CustomEvents<`on${K}`>
>;
// eslint-disable-next-line
declare global {
  // eslint-disable-next-line
  namespace JSX {
    interface IntrinsicElements {
      ["web-footer"]: CustomElement<any, "closeChange" | "openChange">;
    }
  }
}

const FooterComponent: React.FC<{ educateProps: ICustomHeader }> = ({
  educateProps,
}) => {
  const webProps = useMemo(() => {
    return camelcaseKeys(
      {
        ...educateProps,
        modals: Object.values(educateProps.modals),
      },
      { deep: true }
    );
  }, [educateProps]);
  const [customElementProps, ref] = useCustomElement({ data: webProps });
  return <web-footer ref={ref} {...customElementProps}></web-footer>;
};

export default FooterComponent;
